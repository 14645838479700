import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { CookiePermissions } from './CookiePermissions'

const client = new QueryClient()

export default function CookiePermissionsUniversal({ initialConfiguration }) {
  return (
    <QueryClientProvider {...{ client }}>
      <CookiePermissions {...{ initialConfiguration }} />
    </QueryClientProvider>
  )
}
