import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'
import { ButtonTertiary } from '/features/buildingBlocks/Button'

import styles from './CookiePermissions.css'

export function CookiePermissions({ initialConfiguration }) {
  const { __ } = useTranslate()
  const clientConfig = useClientConfig()
  const reportError = useReportError()

  const {
    configuration,
    acceptAll,
    rejectAll,
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const optionChosen = !(configuration?.show ?? false)
  const hasAcceptedConsent = Boolean(configuration?.permissions?.ad_storage === 'granted')

  return (
    <div className={styles.component} data-style-context='light'>
      <ButtonTertiary
        onClick={acceptAll}
        label={__`cookie-consent-accept`}
        dataX='accept-cookies'
        disabled={optionChosen && hasAcceptedConsent}
      />
      <ButtonTertiary
        onClick={rejectAll}
        label={__`cookie-consent-reject`}
        dataX='reject-cookies'
        disabled={optionChosen && !hasAcceptedConsent}
      />
    </div>
  )
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
